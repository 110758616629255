import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-otpverify',
  templateUrl: './otpverify.component.html',
  styleUrls: ['./otpverify.component.scss']
})
export class OtpverifyComponent implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  public registerotpForm: FormGroup;

  public loadingOtp = false;
  public loadingpassword = false;
  public otpmsg: any = '';
  public errorOtp: any = '';
  public errorPassword: any = '';
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */

  constructor(
    private el: ElementRef,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService) {
      this._unsubscribeAll = new Subject();

      // Configure the layout
      this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      }; }

  ngOnInit(): void {
    this.registerotpForm = this._formBuilder.group({
      otp_one: ['', [Validators.required]],
      otp_two: ['', [Validators.required]],
      otp_three: ['', [Validators.required]],
      otp_four: ['', [Validators.required]],
      otp_five: ['', [Validators.required]],
      otp_six: ['', [Validators.required]]
    });
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  get f() {
    return this.loginForm.controls;
  }
  get fotp() {
    return this.registerotpForm.controls;
  }
  onSubmit() {
  }
  onSubmitotp() {
  }
  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + next + '"]');
      invalidControl.focus();
    }
  }
}
