<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
    <div id="user-profile">
      <!-- profile info section -->
      <!-- Modal Post-->
      <ng-template #modalSM let-modal>
        <div class="overlay" *ngIf="isModelLoading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title w-100" id="myModalLabel160">
            <span class="float-left"> <span class="badge badge-glow badge-success">Add New Post</span></span>
          </h5>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
          <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit(0)">
            <div class="modal-body flex-grow-1 ">
              <div class="row m-0">
                <div class="col">
                  <div class="form-group row">
                    <div class="custom-control custom-radio col-3">
                      <input type="radio" id="customRadio1" name="is_img" formControlName="is_img" value="1"
                        (change)="typeChange(1)" class="custom-control-input" checked />
                      <label class="custom-control-label" for="customRadio1">Image</label>
                    </div>
                    <div class="custom-control custom-radio col-3 pl-2">
                      <input type="radio" id="customRadio2" name="is_img" formControlName="is_img" value="0"
                        (change)="typeChange(0)" class="custom-control-input" />
                      <label class="custom-control-label" for="customRadio2">Text</label>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label for="register-image" class="form-label">Image<span class="text-danger">*</span> </label>
                    <input type="file" formControlName="image" class="form-control" placeholder="Enter Image"
                      aria-describedby="register-image" (change)="uploadFile($event,1)"
                      [ngClass]="{ 'is-invalid': submitted && f.image.errors }" />
                    <input type="hidden" formControlName="business_category_id" value="{{business_category_id}}">
                    <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                      <div *ngIf="f.image.errors.required">Image is required</div>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label class="form-label">Product Name<span class="text-danger">*</span></label>
                    <ng-autocomplete #ngAutoCompleteStatic [data]="allName" formControlName="name" [initialValue]=""
                      [minQueryLength]="3" placeholder="Search Product Name Min 3 Characters " [searchKeyword]="keyword"
                      historyIdentifier="allName" [historyHeading]="historyHeading" [historyListMaxNumber]="3"
                      notFoundText="Not found" (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch(business_category_id,$event)' (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #itemTemplateStatic let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label for="register-description" class="form-label">Description<span class="text-danger"></span>
                    </label>
                    <textarea rows="2" formControlName="description" class="form-control" placeholder="Description"
                      aria-describedby="register-description" tabindex="2"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': imageR }">
                    <label for="register-content" class="form-label">Content<span class="text-danger">*</span> </label>
                    <input type="text" formControlName="content" class="form-control" placeholder="Enter Content"
                      aria-describedby="register-content" [ngClass]="{ 'is-invalid': submitted && f.content.errors }" />
                    <div *ngIf="submitted && f.content.errors" class="invalid-feedback">
                      <div *ngIf="f.content.errors.required">Content is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-lg-12 p-0">
                      <input type="hidden" formControlName="market_place_id" class="form-control" value="0" />
                      <button type="submit" class="btn btn-primary" rippleEffect [disabled]="loadingFrm"> <span
                          *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <!-- / Modal Post-->
      <!-- Modal Requirement-->
      <ng-template #modalReq let-modal>
        <div class="overlay" *ngIf="isModelLoading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title w-100" id="myModalLabel160">
            <span class="float-left"> <span class="badge badge-glow badge-success">Add New Requirement</span></span>
          </h5>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
          <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit(1)">
            <div class="modal-body flex-grow-1 ">
              <div class="row m-0">
                <div class="col">
                  <div class="form-group row">
                    <div class="custom-control custom-radio col-3">
                      <input type="radio" id="customRadio1" name="is_img" formControlName="is_img" value="1"
                        (change)="typeChange(1)" class="custom-control-input" checked />
                      <label class="custom-control-label" for="customRadio1">Image</label>
                    </div>
                    <div class="custom-control custom-radio col-3 pl-2">
                      <input type="radio" id="customRadio2" name="is_img" formControlName="is_img" value="0"
                        (change)="typeChange(0)" class="custom-control-input" />
                      <label class="custom-control-label" for="customRadio2">Text</label>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label for="register-image" class="form-label">Image<span class="text-danger">*</span> </label>
                    <input type="file" formControlName="image" class="form-control" placeholder="Enter Image"
                      aria-describedby="register-image" (change)="uploadFile($event,1)"
                      [ngClass]="{ 'is-invalid': submitted && f.image.errors }" />
                    <input type="hidden" formControlName="business_category_id" value="{{business_category_id}}">
                    <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                      <div *ngIf="f.image.errors.required">Image is required</div>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label class="form-label">Product Name<span class="text-danger">*</span></label>
                    <ng-autocomplete #ngAutoCompleteStatic [data]="allName" formControlName="name" [initialValue]=""
                      [minQueryLength]="3" placeholder="Search Product Name Min 3 Characters " [searchKeyword]="keyword"
                      historyIdentifier="allName" [historyHeading]="historyHeading" [historyListMaxNumber]="3"
                      notFoundText="Not found" (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch(business_category_id,$event)' (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #itemTemplateStatic let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': textR }">
                    <label for="register-description" class="form-label">Description<span class="text-danger">*</span>
                    </label>
                    <label for="register-description" class="form-label">Content<span class="text-danger">*</span>
                    </label>
                    <textarea rows="2" formControlName="description" class="form-control" placeholder="Description"
                      aria-describedby="register-description" tabindex="2"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="{ 'd-none': imageR }">
                    <label for="register-content" class="form-label">Content<span class="text-danger">*</span> </label>
                    <input type="text" formControlName="content" class="form-control" placeholder="Enter Content"
                      aria-describedby="register-content" [ngClass]="{ 'is-invalid': submitted && f.content.errors }" />
                    <div *ngIf="submitted && f.content.errors" class="invalid-feedback">
                      <div *ngIf="f.content.errors.required">Content is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-lg-12 p-0">
                      <button type="submit" class="btn btn-primary" rippleEffect [disabled]="loadingFrm"> <span
                          *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <!-- / Modal Requirement-->
      <!-- Modal Report -->
      <ng-template #modalreport let-modal>
        <div class="overlay" *ngIf="isModelLoading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title w-100" id="myModalLabel160">
            <span class="float-left"> <span class="badge badge-glow badge-success">Report Post</span></span>
          </h5>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
          <form class="add-new modal-content pt-0" [formGroup]="newFormReport" (ngSubmit)="onSubmitReport()">
            <div class="modal-body flex-grow-1 ">
              <div class="row m-0">
                <div class="col">
                  <div class="form-group">
                    <label for="register-remarks" class="form-label">Reason For Reporting This Post<span
                        class="text-danger">*</span>
                    </label>
                    <textarea rows="2" formControlName="remarks" class="form-control" placeholder="Write Reason Here..."
                      aria-describedby="register-remarks" tabindex="2"
                      [ngClass]="{ 'is-invalid': submitted && fr.remarks.errors }"></textarea>
                    <div *ngIf="submitted && fr.remarks.errors" class="invalid-feedback">
                      <div *ngIf="fr.remarks.errors.required">Remarks is required</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="col-lg-12 p-0">
                      <button type="submit" class="btn btn-primary" rippleEffect [disabled]="loadingFrm"> <span
                          *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <!-- / Modal Report -->
      <div class="row">
        <!-- left profile info section -->
        <div class="col-lg-3 col-12 order-2 order-lg-1"></div>
        <div class="col-lg-6 col-12 order-1 order-lg-2">
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
              <a ngbNavLink>New Feed</a>
              <ng-template ngbNavContent>
                <section id="profile-info">
                  <div class="row">
                    <!-- left profile info section -->
                    <!-- <div class="col-lg-3 col-12 order-2 order-lg-1"></div> -->
                    <div class="col-lg-12 col-12 order-1 order-lg-2">
                      <div class="text-left">
                        <input type="text" placeholder="Search Post" class="form-control search-box" name="search_text"
                          [(ngModel)]="search_text">
                        <button class="btn btn-success" (click)="searchText(0)">Search</button>
                        <button class="btn btn-success btn-right" (click)="details(modalSM)">Add Post</button>
                      </div>
                      <div class="text-right">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- left profile info section -->
                    <div class="col-lg-3 col-12 order-2 order-lg-1"></div>

                    <!--/ left profile info section -->
                    <!-- center profile info section -->
                    <div class="col-lg-12 col-12 order-1 order-lg-2" *ngIf="postList?.length != 0">
                      <!-- <div class="card" *ngFor="let postRef of data.profileData.post"> -->
                      <div class="card" *ngFor="let posts of postList; let mainndx = index">
                        <div class="card" *ngFor="let ind of posts; let ndx = index">
                          <div class="card-body pb-0">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                              <div class="d-flex justify-content-start align-items-center">
                                <div class="avatar mr-1">
                                  <img [src]="ind.avatar" alt="avatar img" height="50" width="50" />
                                </div>
                                <div class="profile-user-info">
                                  <h4 class="mb-0 text-dark cursor" (click)="userPost(ind.user_id,0)">{{
                                    ind.company_name
                                    |
                                    titlecase}}</h4>
                                  <small class="text-muted">{{ ind.created_at }}</small>
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <!-- <a href="tel:{{ind.mobile}}" class="text-success mr-1"><i data-feather="phone-call"
                                    [size]="20" class="mr-25"></i>
                                </a> -->
                                <div ngbDropdown>
                                  <button rippleEffect class="btn btn-sm border-0 p-0 hide-arrow text-success mr-1"
                                    ngbDropdownToggle type="button" id="dropdownItem{{ndx}}" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i data-feather="phone-call" class="font-medium-3 cursor-pointer"></i>
                                  </button>
                                  <div ngbDropdownMenu class="dropdown-menu-right"
                                    aria-labelledby="dropdownItem{{ndx}}">
                                    <a ngbDropdownItem href="javascript:void(0);">{{ind.mobile}}</a>
                                  </div>
                                </div>
                                <div ngbDropdown>
                                  <button rippleEffect class="btn btn-sm border-0 p-0 hide-arrow" ngbDropdownToggle
                                    type="button" id="dropdownItem{{ndx}}" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
                                  </button>
                                  <div ngbDropdownMenu class="dropdown-menu-right"
                                    aria-labelledby="dropdownItem{{ndx}}">
                                    <a *ngIf="currentUser.data.id == ind.user_id" ngbDropdownItem
                                      href="javascript:void(0);"
                                      (click)="edit(modalSM,ind.id,ind.is_img,mainndx,ndx,0)"><i data-feather="edit-2"
                                        class="mr-25"></i>Edit</a>
                                    <a *ngIf="currentUser.data.id == ind.user_id" ngbDropdownItem
                                      href="javascript:void(0);" (click)="delete(ind.id,0)"><i data-feather="trash"
                                        class="mr-25"></i>Delete</a>
                                    <a ngbDropdownItem href="javascript:void(0);"
                                      (click)="report(ind.id,modalreport)"><i data-feather="alert-octagon"
                                        class="mr-25"></i>Report</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="card-text">
                              <b>{{ ind.name }}</b><br>
                              {{ ind.description }}
                            </p>
                            <p class="card-text" *ngIf="ind.is_img == 0">
                              {{ ind.content }}
                            </p>
                            <!-- post img -->
                            <div *ngIf="ind.is_img == 1">
                              <img class="img-fluid rounded mb-75" src="{{ ind.image }}" alt="avatar img" />
                            </div>
                            <!-- like share -->
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex justify-content-between justify-content-sm-start">
                                <a href="javascript:void(0)" (click)="likePost(ind.id,ind.is_liked,mainndx,ndx,0)"
                                  class="d-flex align-items-center text-muted text-nowrap mr-2">
                                  <i *ngIf="ind.is_liked == '1'"
                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50 red"
                                    aria-hidden="true"></i>
                                  <i *ngIf="ind.is_liked == '' || ind.is_liked == '0'"
                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50" aria-hidden="true"></i>
                                  <span>{{ ind.likes_count }} Like </span>
                                </a>
                                <a href="javascript:void(0)" (click)="unlikelikePost(ind.id,ind.is_liked,mainndx,ndx,1)"
                                  class="d-flex align-items-center text-muted text-nowrap">
                                  <i *ngIf="ind.is_liked == '0'"
                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50 red"></i>
                                  <i *ngIf="ind.is_liked == '' || ind.is_liked == '1'"
                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i>
                                  <span>{{ ind.unlikes_count }} Unlike </span>
                                </a>
                              </div>
                              <div *ngIf="currentUser.data.id == '862'">
                                <button routerLink="/login" type="button"
                                  class="btn btn-sm btn-outline-secondary waves-effect" rippleEffect>
                                  <i data-feather="slash" class="mr-25"></i>Block
                                </button>
                              </div>
                              <div *ngIf="currentUser.data.id != '862'">
                                <button routerLink="/profile/{{ ind.user_id }}" type="button"
                                  class="btn btn-sm btn-outline-secondary waves-effect" rippleEffect>
                                  <i data-feather="slash" class="mr-25"></i>Block
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- reload button -->
                  <div class="row" *ngIf="page <= last_page">
                    <div class="col-12 text-center" *ngIf="postList?.length != 0">
                      <button type="button" class="btn btn-sm btn-primary block-element border-0 mb-1" rippleEffect
                        (click)="loadMore()">
                        <span role="status" aria-hidden="true" [class.spinner-border]="loadMoreRef"
                          class="spinner-border-sm"></span>
                        Load More
                      </button>
                    </div>
                  </div>
                  <!--/ reload button -->
                </section>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Requirement</a>
              <ng-template ngbNavContent>
                <section id="profile-info">
                  <div class="row">
                    <!-- left profile info section -->
                    <!-- <div class="col-lg-3 col-12 order-2 order-lg-1"></div> -->
                    <div class="col-lg-12 col-12 order-1 order-lg-2">
                      <div class="text-left">
                        <input type="text" placeholder="Search Requirement" class="form-control search-box"
                          name="search_text" [(ngModel)]="search_text">
                        <button class="btn btn-success" (click)="searchText(1)">Search</button>
                        <button class="btn btn-success btn-right" (click)="details(modalReq)">Add Requirement</button>
                      </div>
                      <div class="text-right">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- left profile info section -->
                    <div class="col-lg-3 col-12 order-2 order-lg-1"></div>
                    <!--/ left profile info section -->
                    <!-- center profile info section -->
                    <div class="col-lg-12 col-12 order-1 order-lg-2" *ngIf="requirementpostList?.length != 0">
                      <!-- <div class="card" *ngFor="let postRef of data.profileData.post"> -->
                      <div class="card" *ngFor="let posts of requirementpostList; let mainndx = index">
                        <div class="card" *ngFor="let ind of posts; let ndx = index">
                          <div class="card-body pb-0">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                              <div class="d-flex justify-content-start align-items-center">
                                <div class="avatar mr-1">
                                  <img [src]="ind.avatar" alt="avatar img" height="50" width="50" />
                                </div>
                                <div class="profile-user-info">
                                  <h4 class="mb-0 text-dark cursor" (click)="userPost(ind.user_id,1)">{{
                                    ind.company_name
                                    |
                                    titlecase}}</h4>
                                  <small class="text-muted">{{ ind.created_at }}</small>
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <!-- <a href="tel:{{ind.mobile}}" class="text-success mr-1"><i data-feather="phone-call"
                                    [size]="20" class="mr-25"></i></a> -->
                                <div ngbDropdown>
                                  <button rippleEffect class="btn btn-sm border-0 p-0 hide-arrow text-success mr-1"
                                    ngbDropdownToggle type="button" id="dropdownItem{{ndx}}" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i data-feather="phone-call" class="font-medium-3 cursor-pointer"></i>
                                  </button>
                                  <div ngbDropdownMenu class="dropdown-menu-right"
                                    aria-labelledby="dropdownItem{{ndx}}">
                                    <a ngbDropdownItem href="javascript:void(0);">{{ind.mobile}}</a>
                                  </div>
                                </div>
                                <div ngbDropdown>
                                  <button rippleEffect class="btn btn-sm border-0 p-0 hide-arrow" ngbDropdownToggle
                                    type="button" id="dropdownItem{{ndx}}" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
                                  </button>
                                  <div ngbDropdownMenu class="dropdown-menu-right"
                                    aria-labelledby="dropdownItem{{ndx}}">
                                    <a *ngIf="currentUser.data.id == ind.user_id" ngbDropdownItem
                                      href="javascript:void(0);" (click)="edit(modalReq,ind.id,ind.is_img,mainndx,ndx,1)"><i data-feather="edit-2"
                                        class="mr-25"></i>Edit</a>
                                    <a *ngIf="currentUser.data.id == ind.user_id" ngbDropdownItem
                                      href="javascript:void(0);" (click)="delete(ind.id,1)"><i data-feather="trash"
                                        class="mr-25"></i>Delete</a>
                                    <a ngbDropdownItem href="javascript:void(0);"
                                      (click)="report(ind.id,modalreport)"><i data-feather="alert-octagon"
                                        class="mr-25"></i>Report</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="card-text">
                              <b>{{ ind.name }}</b><br>
                              {{ ind.description }}
                            </p>
                            <p class="card-text" *ngIf="ind.is_img == 0">
                              {{ ind.content }}
                            </p>
                            <!-- post img -->
                            <div *ngIf="ind.is_img == 1">
                              <img class="img-fluid rounded mb-75" src="{{ ind.image }}" alt="avatar img" />
                            </div>
                            <!-- like share -->
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex justify-content-between justify-content-sm-start">
                                <a href="javascript:void(0)" (click)="likePost(ind.id,ind.is_liked,mainndx,ndx,2)"
                                  class="d-flex align-items-center text-muted text-nowrap mr-2">
                                  <i *ngIf="ind.is_liked == '1'"
                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50 red"
                                    aria-hidden="true"></i>
                                  <i *ngIf="ind.is_liked == '' || ind.is_liked == '0'"
                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50" aria-hidden="true"></i>
                                  <span>{{ ind.likes_count }} Like </span>
                                </a>
                                <a href="javascript:void(0)" (click)="unlikelikePost(ind.id,ind.is_liked,mainndx,ndx,3)"
                                  class="d-flex align-items-center text-muted text-nowrap">
                                  <i *ngIf="ind.is_liked == '0'"
                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50 red"></i>
                                  <i *ngIf="ind.is_liked == '' || ind.is_liked == '1'"
                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i>
                                  <span>{{ ind.unlikes_count }} Unlike </span>
                                </a>
                              </div>
                              <div *ngIf="currentUser.data.id == '862'">
                                <button routerLink="/login" type="button"
                                  class="btn btn-sm btn-outline-secondary waves-effect" rippleEffect>
                                  <i data-feather="slash" class="mr-25"></i>Block
                                </button>
                              </div>
                              <div *ngIf="currentUser.data.id != '862'">
                                <button routerLink="/profile/{{ ind.user_id }}" type="button"
                                  class="btn btn-sm btn-outline-secondary waves-effect" rippleEffect>
                                  <i data-feather="slash" class="mr-25"></i>Block
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- reload button -->
                  <div class="row" *ngIf="reqpage <= reqlast_page">
                    <div class="col-12 text-center" *ngIf="requirementpostList?.length != 0">
                      <button type="button" class="btn btn-sm btn-primary block-element border-0 mb-1" rippleEffect
                        (click)="loadMorereq()">
                        <span role="status" aria-hidden="true" [class.spinner-border]="reqloadMoreRef"
                          class="spinner-border-sm"></span>
                        Load More
                      </button>
                    </div>
                  </div>
                  <!--/ reload button -->
                </section>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
          <!--/ profile info section -->
        </div>
      </div>
    </div>
  </div>
</div>