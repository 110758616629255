import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PartypostService } from 'app/_services/partypost.service';

@Component({
  selector: 'app-partypost',
  templateUrl: './partypost.component.html',
  styleUrls: ['./partypost.component.scss']
})
export class PartypostComponent implements OnInit {
  public partypostList: any = [];
  public business_industry_id: any = 0;
  public post_profile: any = 0;
  public post_id: any = 0;
  public loadMoreRef = false;
  public page: number = 1;
  public last_page: number = 1;

  public basicCurrentRate = 1;
  public newFormAdd: FormGroup;
  public submitted = false;
  public error = "";
  public loading = false;
  public isModelLoading: Boolean = true;
  public commentDetails: any;
  public likeDetails: any;

  public stateDropDown: any;
  public districtDropDown: any;

  public commnetItemValues: any[] = [];
  public currentUser: any;

  constructor(
    private partypostService: PartypostService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.partypostsList();
    this.getStateDropdown();

    this.newFormAdd = this._formBuilder.group({
      customer_name: ["", Validators.required],
      company_name: ["", Validators.required],
      gst_no: [""],
      pan_no: [""],
      mobile: ["", Validators.required],
      amount: ["", Validators.required],
      due_date: ["", Validators.required],
      state_id: [""],
      district_id: [""],
      city: [""],
      address: [""],
      description: [""],
    });
  }

  getStateDropdown() {
    this.partypostService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getDist(event: any) {
    this.partypostService.getDistrict(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.districtDropDown = data.data;
          this.f.district_id.setValue('');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  get f() {
    return this.newFormAdd.controls;
  }

  partypostsList() {
    this.partypostService.getpost(this.page).subscribe(
      (response) => {
        if (response.status) {
          this.partypostList.push(response.data.data);
          this.last_page = response.data.last_page;
          this.page += 1;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  loadMore() {
    this.loadMoreRef = !this.loadMoreRef;
    setTimeout(() => {
      this.loadMoreRef = !this.loadMoreRef;
    }, 2000);
    this.partypostsList();
  }

  details(modalSM) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.isModelLoading = false;

      this.modalService.open(modalSM, {
        centered: true,
        size: 'xl'
      });
    }
  }

  Reactdetails(reactmodal, post_id: any) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.modalService.open(reactmodal, {
        centered: true,
        size: 'lg'
      });
      this.partypostService.getcomment(post_id).subscribe(
        data => {
          this.isModelLoading = false;
          if (data.status) {
            this.commentDetails = data.data.data;
          } else {
            this._toastrService.error(data.errorMessage, 'Error!');
          }
        },
        err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
        }
      );
      this.partypostService.getlikes(post_id).subscribe(
        data => {
          this.isModelLoading = false;
          if (data.status) {
            this.likeDetails = data.data.data;
          } else {
            this._toastrService.error(data.errorMessage, 'Error!');
          }
        },
        err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
        }
      );
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    }
    else {
      this.business_industry_id = 1;
      this.loading = true;
      const { customer_name, company_name, gst_no, pan_no, mobile, amount, due_date, state_id, district_id, city, address, description } = this.newFormAdd.controls;

      this.partypostService.add(customer_name.value, company_name.value, gst_no.value, pan_no.value, mobile.value, amount.value, due_date.value, state_id.value, district_id.value, city.value, address.value, description.value, this.business_industry_id).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.newFormAdd.reset();
            this.modalService.dismissAll();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  likePost(post_id, is_liked, mainndx, ndx) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "1";
      }

      this.partypostService.likepost(post_id, like).subscribe(
        data => {
          if (data.status) {
            this.partypostList[mainndx][ndx].is_liked = like;
            this.partypostList[mainndx][ndx].likes_count = data.data.likes_count;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  unlikelikePost(post_id, is_liked, mainndx, ndx) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "0";
      }

      this.partypostService.likepost(post_id, like).subscribe(
        data => {
          if (data.status) {
            this.partypostList[mainndx][ndx].is_liked = like;
            this.partypostList[mainndx][ndx].unlikes_count = data.data.unlikes_count;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  addComment(post_id, comment) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.partypostService.commentadd(post_id, comment).subscribe(
        data => {
          if (data.status) {
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.commnetItemValues = [];
          }else{
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  commentlike(comment_id, is_liked, ndx) {
    let like = '';
    if (is_liked == '') {
      like = "1";
    }

    this.partypostService.likecomment(comment_id, like).subscribe(
      data => {
        if (data.status) {
          this.commentDetails[ndx].is_liked = like;
          this.commentDetails[ndx].likes_comment_count = data.data.likes_comment_count;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  commentunlike(comment_id, is_liked, ndx) {
    let like = '';
    if (is_liked == '') {
      like = "0";
    }

    this.partypostService.unlikecomment(comment_id, like).subscribe(
      data => {
        if (data.status) {
          this.commentDetails[ndx].is_liked = like;
          this.commentDetails[ndx].unlikes_comment_count = data.data.unlikes_comment_count;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
}
