import { Role } from './role';

export class User {
  id: number;
  business_industry_id: number;
  business_industry_name: string;
  company_name: string;
  name: string;
  password: string;
  mobile: string;
  is_verify: string;
  is_profile_filled: string;
  avatar: string;
  role: Role;
  token?: string;
}
