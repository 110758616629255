import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProfileService } from 'app/_services/profile.service';
import { Console } from 'console';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public profileList: any = [];
  public ratingList: any = [];
  public user_id: any = 0;
  public post_profile: any = 0;
  public post_id: any = 0;
  public basicCurrentRate = 1;

  public newFormReport: FormGroup;
  public newFormAdd: FormGroup;

  public submitted = false;
  public error = "";
  public loadingFrm: boolean = false;
  public isLoading: boolean = false;

  public currentUser: any;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.user_id = this.route.snapshot.params['id'];
      this.profilesList(this.user_id);
      this.ratingsList(this.user_id);

      this.newFormAdd = this._formBuilder.group({
        review: [""]
      });

      this.newFormReport = this._formBuilder.group({
        remarks: ["", Validators.required]
      });
    }
  }

  get f() {
    return this.newFormAdd.controls;
  }

  get fr() {
    return this.newFormReport.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { review } = this.newFormAdd.controls;
      this.profileService.addRating(this.basicCurrentRate, review.value, this.user_id).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormAdd.reset();
            this.reloadList();
            this.basicCurrentRate = 1;
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormAdd.reset();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  onSubmitReport() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormReport.invalid) {
      this.newFormReport.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      this.post_profile = "2";
      this.post_id = this.user_id;
      const { remarks } = this.newFormReport.controls;
      this.profileService.addReport(remarks.value, this.currentUser.data.id, this.post_id, this.post_profile).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormReport.reset();
            this.modalService.dismissAll();
            // this.page = 1;
            // this.postList = [];
            // this.reloadList();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormReport.reset();
            this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  profilesList(user_id: any) {
    this.profileService.getprofile(user_id).subscribe(
      (response) => {
        if (response.status) {
          this.profileList = response.data;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  ratingsList(user_id: any) {
    this.profileService.getrating(user_id).subscribe(
      (response) => {
        if (response.status) {
          this.ratingList = response.data.data;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  report(user_id, modalreport) {
    this.user_id = user_id;
    this.modalService.open(modalreport, {
      centered: true,
      size: 'lg'
    });
  }

  reloadList() {
    this.user_id = this.route.snapshot.params['id'];
    this.profilesList(this.user_id);
    this.ratingsList(this.user_id);
  }

  block(id: any, is_blocked) {
    let title = '';
    let text = '';
    let ctext = '';
    let stext = '';
    if (is_blocked == '0') {
      is_blocked = "1";
      title = "Block!";
      text = "You won't block!";
      ctext = "Yes, block it!";
      stext = "User Block Successfully";
    } else if (is_blocked == '1') {
      is_blocked = "0";
      title = "Unblock!";
      text = "You won't unblock!";
      ctext = "Yes, unblock it!";
      stext = "User Unblock Successfully";
    }
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: ctext,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.profileService.block(id, is_blocked).subscribe(
          (response) => {
            if (response.status) {
              // me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: title,
                text: stext,
                timer: 2000
                // customClass: {
                //   confirmButton: "btn btn-success",
                // },
              });
              // this.reloadList();

            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }
}
