<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
        <div id="user-profile">
            <!-- profile info section -->
            <!-- Modal Report -->
            <ng-template #modalreport let-modal>
                <div class="overlay" *ngIf="isModelLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title w-100" id="myModalLabel160">
                        <span class="float-left"> <span class="badge badge-glow badge-success">Report Post</span></span>
                    </h5>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>
                    <form class="add-new modal-content pt-0" [formGroup]="newFormReport" (ngSubmit)="onSubmitReport()">
                        <div class="modal-body flex-grow-1 ">
                            <div class="row m-0">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="register-remarks" class="form-label">Reason For Reporting This
                                            Post<span class="text-danger">*</span>
                                        </label>
                                        <textarea rows="2" formControlName="remarks" class="form-control"
                                            placeholder="Write Reason Here..." aria-describedby="register-remarks"
                                            tabindex="2"
                                            [ngClass]="{ 'is-invalid': submitted && fr.remarks.errors }"></textarea>
                                        <div *ngIf="submitted && fr.remarks.errors" class="invalid-feedback">
                                            <div *ngIf="fr.remarks.errors.required">Remarks is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-lg-12 p-0">
                                            <button type="submit" class="btn btn-primary" rippleEffect
                                                [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <!-- / Modal Report -->
            <section id="profile-info">
                <div class="row">
                    <!-- left profile info section -->
                    <div class="col-lg-3 col-12 order-2 order-lg-1">

                    </div>
                    <!--/ left profile info section -->
                    <!-- center profile info section -->
                    <div class="col-lg-6 col-12 order-1 order-lg-2">
                        <div class="card">
                            <div class="card-body">
                                <!-- header media -->
                                <div class="media mb-1">
                                    <a href="javascript:void(0);" class="mr-25">
                                        <img [src]="profileList.avatar" class="rounded mr-50" alt="profile image"
                                            height="76" width="80" />
                                    </a>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <div class="avatar avatar-tag bg-light-primary mr-1 profile-ico">
                                        <i class="fa fa-building" aria-hidden="true"></i>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold">{{profileList.company_name}}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <div class="avatar avatar-tag bg-light-primary mr-1 profile-ico">
                                        <i data-feather="map-pin" class="font-medium-3"></i>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold">{{profileList.district}} -
                                            {{profileList.state}}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <div class="avatar avatar-tag bg-light-primary mr-1 profile-ico">
                                        <i data-feather="phone-call" class="font-medium-3"></i>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold">{{profileList.mobile}}</span>
                                    </div>
                                </div>
                                <!--/ header media -->
                                <div class="col-12">
                                    <button *ngIf="profileList.is_blocked == '0'" (click)="block(profileList.user_id,profileList.is_blocked)" type="submit" class="btn btn-primary mt-2 mr-1">
                                        <i class="fa fa-ban" aria-hidden="true"></i> Block
                                    </button>
                                    <button *ngIf="profileList.is_blocked == '1'" (click)="block(profileList.user_id,profileList.is_blocked)" type="submit" class="btn btn-primary mt-2 mr-1">
                                        <i class="fa fa-ban" aria-hidden="true"></i> Unblock
                                    </button>
                                    <button (click)="report(profileList.user_id,modalreport)" type="submit"
                                        class="btn btn-primary mt-2 mr-1">
                                        <i data-feather="alert-octagon" class="font-medium-3"></i> Report
                                    </button>
                                </div>
                                <!-- form -->
                                <form class="validate-form mt-2" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="rating rating-lg">
                                                <ngb-rating [(rate)]="basicCurrentRate" class="outline-none" [max]="5">
                                                </ngb-rating>
                                            </div>

                                            <div class="form-group">
                                                <label for="register-review" class="form-label">Write a
                                                    review....<span class="text-danger">*</span>
                                                </label>
                                                <textarea rows="2" formControlName="review" class="form-control"
                                                    placeholder="Write a review...." aria-describedby="register-review"
                                                    tabindex="2" [ngClass]="">
                                                    </textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-primary mr-1" rippleEffect
                                                [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Submit
                                            </button>
                                            <button type="reset" class="btn btn-outline-secondary"
                                                rippleEffect>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                                <!--/ form -->
                            </div>
                            <div class="col-lg-6 col-12 order-1 order-lg-2" *ngIf="ratingList?.length != 0">
                                <!-- <div class="card" *ngFor="let postRef of data.profileData.post"> -->
                                <!-- <div class="card" *ngFor="let posts of ratingList; let mainndx = index"> -->
                                    <div class="card" *ngFor="let ind of ratingList; let ndx = index">
                                        <div class="card-body pb-0">
                                            <div class="d-flex justify-content-between align-items-center mb-1">
                                                <div class="d-flex justify-content-start align-items-center">
                                                    <div class="avatar mr-1">
                                                        <img [src]="ind.avatar" alt="avatar img" height="50"
                                                            width="50" />
                                                    </div>
                                                    <div class="profile-user-info">
                                                        <h4 class="mb-0 text-dark">{{ ind.name}}</h4>
                                                        <div class="rating">
                                                            <ngb-rating [(rate)]="ind.rating" class="outline-none" [max]="5">
                                                            </ngb-rating>
                                                        </div>
                                                        <small class="text-right">{{ ind.created_at }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--/ profile info section -->
        </div>
    </div>
</div>