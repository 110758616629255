import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param mobile
   * @param password
   * @returns user
   */
  // login(email: string, password: string) {
  //   return this._http
  //     .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
  //     .pipe(
  //       map(user => {
  //         // login successful if there's a jwt token in the response
  //         if (user && user.token) {
  //           // store user details and jwt token in local storage to keep user logged in between page refreshes
  //           localStorage.setItem('currentUser', JSON.stringify(user));

  //           // Display welcome toast!
  //           setTimeout(() => {
  //             this._toastrService.success(
  //               'You have successfully logged in as an ' +
  //                 user.role +
  //                 ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
  //               '👋 Welcome, ' + user.firstName + '!',
  //               { toastClass: 'toast ngx-toastr', closeButton: true }
  //             );
  //           }, 2500);

  //           // notify
  //           this.currentUserSubject.next(user);
  //         }

  //         return user;
  //       })
  //     );
  // }

  login(mobile: string, password: string, type: any = 1) {
    return this._http
      .post<any>(`${environment.apiUrl}login`, { mobile, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.data?.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            if (user.data.is_verify == 1 && user.data.name != null) {
              setTimeout(() => {
                this._toastrService.success(
                  'You have successfully logged Enjoy!',
                  'Welcome, ' + user.data.name + '!',
                  { toastClass: 'toast ngx-toastr', closeButton: true }
                );
              }, 500);
            }
            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  verifyotp(mobile: any, otp: any, is_register: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}verify-otp`, { mobile, otp,is_register })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
