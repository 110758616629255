import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { JwtInterceptorProviders } from './auth/helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { PostComponent } from './component/post/post.component';
import { ProfileComponent } from './component/profile/profile.component';
import { PartypostComponent } from './component/partypost/partypost.component';
import { UserprofileComponent } from './component/userprofile/userprofile.component';
import { RequirementComponent } from './component/requirement/requirement.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';



@NgModule({
  declarations: [AppComponent, PostComponent, ProfileComponent, PartypostComponent, UserprofileComponent, RequirementComponent, ForgotPasswordComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    // RouterModule.forRoot(appRoutes, {
    //   scrollPositionRestoration: 'enabled', // Add options right here
    //   relativeLinkResolution: 'legacy'
    // }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    AutocompleteLibModule
  ],

  providers: [JwtInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
