import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserprofileService } from 'app/_services/userprofile.service';
import { PartypostService } from 'app/_services/partypost.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss']
})
export class UserprofileComponent implements OnInit {

  public newFormAdd: FormGroup;
  public submitted = false;
  public error = "";
  public loading = false;
  public isModelLoading: Boolean = true;
  public loadingFrm: boolean = false;
  public user_id: any = 0;
  public state_id: any = 0;

  public stateDropDown: any;
  public distictDropDown: any;
  public industryDropDown: any;
  public districtDropDown: any;

  constructor(
    private partypostService: PartypostService,
    private userprofileService: UserprofileService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.getStateDropdown();
    this.getIndustryDropDown();

    this.user_id = this.route.snapshot.params['id'];
    this.userProfileEdit(this.user_id);

    this.newFormAdd = this._formBuilder.group({
      person_name: ["", Validators.required],
      company_name: ["", Validators.required],
      gst_no: [""],
      pan_no: [""],
      email: ["",Validators.email],
      mobile: ["", Validators.required],
      state_id: ["", Validators.required],
      district_id: ["", Validators.required],
      city: [""],
      address: [""],
      business_industry_id: ["", Validators.required],
    });
    // this.getDistictDropdown();

  }

  userProfileEdit(user_id: any) {
    this.loadingFrm = true;
    this.userprofileService.edit(user_id).subscribe(
      data => {
        if (data.status) {
          this.loadingFrm = false;
          
          this.getDistictDropdown(data.data.state_id);
          if (data.data.business_category_id != '0') {
            this.newFormAdd.setValue({
              company_name: data.data.company_name,
              person_name: data.data.user_name,
              gst_no: data.data.gst_no,
              pan_no: data.data.pan_no,
              email: data.data.email,
              mobile: data.data.mobile,
              state_id: data.data.state_id,
              district_id: data.data.district_id,
              city: data.data.city,
              address: data.data.address,
              business_industry_id: data.data.business_category_id,
            });
          } else {
            this.newFormAdd.setValue({
              company_name: data.data.company_name,
              person_name: data.data.user_name,
              gst_no: data.data.gst_no,
              pan_no: data.data.pan_no,
              email: data.data.email,
              mobile: data.data.mobile,
              state_id: data.data.state_id,
              district_id: data.data.district_id,
              city: data.data.city,
              address: data.data.address,
              business_industry_id: ''
            });
          }
          this.user_id = data.data.user_id;

          this.state_id = data.data.state_id;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  getIndustryDropDown() {
    this.userprofileService.getIndustry().subscribe(
      data => {
        if (data.status) {
          this.industryDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getDistictDropdown(state_id: any) {
    this.partypostService.getDistrict(state_id).subscribe(
      data => {
        if (data.status) {
          this.distictDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  getStateDropdown() {
    this.partypostService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getDist(event: any) {
    this.partypostService.getDistrict(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.distictDropDown = data.data;
          this.f.district_id.setValue('');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { person_name, company_name, address, state_id, district_id, city, email, gst_no, pan_no, business_industry_id } = this.newFormAdd.controls;
      this.userprofileService.update(person_name.value, company_name.value, address.value, state_id.value, district_id.value, city.value, email.value, gst_no.value, pan_no.value, business_industry_id.value, this.user_id).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormAdd.reset();
            this._router.navigate(["/home"]);
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            // this.newFormAdd.reset();
            // this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }
}
