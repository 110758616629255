import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { ForgotPasswordService } from 'app/_services/forgot-password.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public coreConfig: any;
  public forgotPasswordForm: FormGroup;
  public registerPasswordForm: FormGroup;
  public submitted = false;
  public submittedPassword = false;
  public otp_screen = false;
  public password_screen = false;
  public loading = false;
  public loadingOtp = false;
  public loadingpassword = false;
  public registerotpForm: FormGroup;
  public passwordTextType: boolean;
  public passwordTextType2: boolean;
  public otpmsg: any = '';
  public errorOtp: any = '';
  public error: any = '';
  public errorPassword: any = '';
  public user_id = '0';
  
  constructor(
    private el: ElementRef,
    private forgotPasswordService: ForgotPasswordService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private customValidator: CustomvalidationService,
    private _toastrService: ToastrService,
    private authenticationService: AuthenticationService) {

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  get fotp() {
    return this.registerotpForm.controls;
  }

  get fp() {
    return this.registerPasswordForm.controls;
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      mobile: ['', [Validators.required]]
    });

    this.registerPasswordForm = this._formBuilder.group({   
      // password: ['', [Validators.required,Validators.compose([this.customValidator.patternValidator()])]],
      password: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    }, {
      validator: this.customValidator.MatchPassword('password', 'password2'),
    });

    this.registerotpForm = this._formBuilder.group({
      otp_one: ['', [Validators.required]],
      otp_two: ['', [Validators.required]],
      otp_three: ['', [Validators.required]],
      otp_four: ['', [Validators.required]],
      otp_five: ['', [Validators.required]],
      otp_six: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    else {
      this.loading = true;
      this.otp_screen = false;
      this.password_screen = false;

      this.forgotPasswordService.sentOtp(this.f.mobile.value).subscribe(
        data => {
          this.loading = false;
          this.loadingOtp = false;
          if (data.status) {
            this.otp_screen = true;
            this.password_screen = false;
            this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            console.log('hello');           
              this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
           
          }
        },
        err => {
          console.log(err);
        }
      );

    }
  }

  onSubmitotp() {
    this.submitted = true;
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    // stop here if form is invalid
    if (this.registerotpForm.invalid) {

    }
    else {
      this.otpmsg = '';
      this.loadingOtp = true;
      this.otpmsg = this.fotp.otp_one.value + '' + this.fotp.otp_two.value + '' + this.fotp.otp_three.value + '' + this.fotp.otp_four.value + '' + this.fotp.otp_five.value + '' + this.fotp.otp_six.value;
      this.forgotPasswordService.verifyotp(this.f.mobile.value, this.otpmsg).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.otp_screen = false;
            this.password_screen = true;
            this.user_id = data.data.id;
            console.log(this.user_id);
            this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
           this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          
        }
      );
    }
  }

  onSubmitpassword() {
    console.log(this.user_id);

    this.submittedPassword = true;

    // stop here if form is invalid
    if (this.registerPasswordForm.invalid) {
      return;
    }
    else {
      this.loadingpassword = true;

      this.forgotPasswordService.changePassword(this.user_id, this.fp.password.value).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this._router.navigate(['/login']);
            this._toastrService.success('Password Reset Successfully', 'Congratulations!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.errorPassword = data.message;
          }
        },
        err => {
          
        }
      );

    }
  }

  togglePasswordTextType(me) {
    if (me == 0) {
      this.passwordTextType = !this.passwordTextType;
    }
    else {
      this.passwordTextType2 = !this.passwordTextType2;
    }
  }

  resendotp() {
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    this.forgotPasswordService.resendotp(this.f.mobile.value).subscribe(
      data => {
        if (data.status) {
          this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        console.log(err);
        this._toastrService.error(err, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );

  }

  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + next + '"]');
      invalidControl.focus();
    }
  }
}
