<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="faq">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="d-flex align-items-center">
            <div class="avatar avatar-tag bg-light-primary mr-1">
              <i data-feather="trello" [size]="22"></i>
            </div>
            <div>
              <h4 class="mb-0 mr-1">Industry List</h4>
              <!-- <span>For Seller</span> -->
            </div>

            <!-- <div class="avatar avatar-tag bg-light-primary mr-1">
              <i data-feather="file-text" [size]="22"></i>
            </div>
            <div>
              <h4 class="mb-0 mr-1"><a routerLink="/requirement"> Requirement </a></h4>
            </div> -->

            <!-- <div class="avatar avatar-tag bg-light-primary mr-1">
              <i data-feather="file-text" [size]="22"></i>
            </div>
            <div>
              <h4 class="mb-0"><a routerLink="/partypost"> Black List </a></h4>
            </div> -->
          </div>
          <div class="accordion collapse-icon collapse-margin mt-2">
            <ngb-accordion [closeOthers]="true" activeIds="accordion-0" *ngIf="industryList?.length != 0">
              <ngb-panel id="accordion-{{ndx}}" *ngFor="let ind of industryList; let ndx = index">
                <ng-template ngbPanelTitle>
                  <span class="lead collapse-title">{{ ind.name }}</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xxl-3 mb-1 " *ngFor="let indSub of ind.category">
                      <a routerLink="/post/{{ indSub.id }}" class="blog-title-truncate text-body-heading">
                        <div class="card card-bg">
                          <div class="card-body">
                            <h4 class="card-title mb-0 blog-title-truncate text-body-heading">
                              {{
                              indSub.name }}
                            </h4>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xxl-3 mb-3" *ngIf="ndx == 0">
                      <a routerLink="/partypost/" class="blog-title-truncate text-body-heading">
                      <div class="card card-bg">
                        <div class="card-body">
                          <h4 class="card-title mb-0 blog-title-truncate text-body-heading">
                            Black List
                          </h4>
                        </div>
                      </div>
                    </a>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- <div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="collapsible">
      <div class="row">
        <div class="col-sm-12">
            <div class="card-body collapse-icon">
              <p class="card-text">Idustry List</p>
              <ngb-accordion [closeOthers]="true" activeIds="accordion-0"  *ngIf="industryList?.length != 0">
                <ngb-panel id="accordion-{{ndx}}" *ngFor="let ind of industryList; let ndx = index">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">{{ ind.name }}</span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="" *ngFor="let ind1 of ind.category">
                      <a href="" routerLink="/post/{{ ind1.id }}">{{ ind1.name }}</a>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
        </div>
      </div>
    </section>
  </div>
</div> -->