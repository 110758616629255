<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
        <div id="user-profile">
            <!-- Modal -->
            <ng-template #modalSM let-modal>
                <div class="overlay" *ngIf="isModelLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title w-100" id="myModalLabel160">
                        <span class="float-left"> <span class="badge badge-glow badge-success">Add New
                                Post</span></span>
                    </h5>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>
                    <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                        <div class="modal-body flex-grow-1 ">
                            <div class="row m-0">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="register-company_name" class="form-label">Company Name<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="company_name" class="form-control"
                                            placeholder="Enter Company Name" aria-describedby="register-company_name"
                                            [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                                        <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.company_name.errors.required">Company Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-customer_name" class="form-label">Person Name<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="customer_name" class="form-control"
                                            placeholder="Enter Person Name" aria-describedby="register-customer_name"
                                            [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }" />
                                        <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.customer_name.errors.required">Person Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-gst_no" class="form-label">GST No<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="gst_no" class="form-control"
                                            placeholder="Enter GST No" aria-describedby="register-gst_no"
                                            [ngClass]="{ 'is-invalid': submitted && f.gst_no.errors }" />
                                        <div *ngIf="submitted && f.gst_no.errors" class="invalid-feedback">
                                            <div *ngIf="f.gst_no.errors.required">GST No is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-pan_no" class="form-label">PAN No<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="pan_no" class="form-control"
                                            placeholder="Enter PAN No" aria-describedby="register-pan_no"
                                            [ngClass]="{ 'is-invalid': submitted && f.pan_no.errors }" />
                                        <div *ngIf="submitted && f.pan_no.errors" class="invalid-feedback">
                                            <div *ngIf="f.pan_no.errors.required">PAN No is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="register-mobile" class="form-label">Mobile<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="mobile" class="form-control"
                                            placeholder="Enter Mobile" aria-describedby="register-mobile"
                                            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-amount" class="form-label">Amount<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="amount" class="form-control"
                                            placeholder="Enter Amount" aria-describedby="register-amount"
                                            [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                                        <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                            <div *ngIf="f.amount.errors.required">Amount is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-due_date" class="form-label">Due Date<span
                                                class="text-danger">*</span> </label>
                                        <input type="date" formControlName="due_date" class="form-control"
                                            placeholder="Enter Due Date" aria-describedby="register-due_date"
                                            [ngClass]="{ 'is-invalid': submitted && f.due_date.errors }" />
                                        <div *ngIf="submitted && f.due_date.errors" class="invalid-feedback">
                                            <div *ngIf="f.due_date.errors.required">Due Date is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-state_id" class="form-label">Select State<span
                                                class="text-danger">*</span></label>
                                        <select formControlName="state_id" class="form-control"
                                            (change)="getDist($event)" placeholder="Select State"
                                            aria-describedby="register-state_id" tabindex="9"
                                            [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                            <option value="" selected disabled>Select State</option>
                                            <option *ngFor="let state of stateDropDown" [value]="state.id">
                                                {{state.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="register-district_id" class="form-label">Select District<span
                                                class="text-danger">*</span></label>
                                        <select formControlName="district_id" class="form-control"
                                            placeholder="Select District" aria-describedby="register-district_id"
                                            tabindex="9"
                                            [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }">
                                            <option value="" selected disabled>Select District</option>
                                            <option *ngFor="let dis of districtDropDown" [value]="dis.id">{{dis.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.district_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-city" class="form-label">City<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="city" class="form-control"
                                            placeholder="Enter City" aria-describedby="register-city"
                                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                            <div *ngIf="f.city.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-address" class="form-label">Address<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" formControlName="address" class="form-control"
                                            placeholder="Enter Address" aria-describedby="register-address"
                                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                            <div *ngIf="f.address.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="register-description" class="form-label">Description<span
                                                class="text-danger">*</span>
                                        </label>
                                        <textarea rows="2" formControlName="description" class="form-control"
                                            placeholder="Description" aria-describedby="register-description"
                                            tabindex="2"
                                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                            <div *ngIf="f.description.errors.required">Description is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="submit" class="btn btn-primary" rippleEffect
                                            [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <!-- / Modal -->
            <!-- React Modal -->
            <ng-template #reactmodal let-modal>
                <div class="overlay" *ngIf="isModelLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title w-100" id="myModalLabel160">
                        <span class="float-left">
                            <span class="badge badge-glow badge-success">Comments & Likes Details</span>
                        </span>
                    </h5>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li ngbNavItem>
                            <a ngbNavLink>Comment</a>
                            <ng-template ngbNavContent>
                                <div class="card" *ngFor="let ind of commentDetails; let ndx = index">
                                    <div class="card-body pb-0">
                                        <div class="d-flex justify-content-between align-items-center mb-1">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div class="avatar mr-1">
                                                    <img [src]="ind.avatar" alt="avatar img" height="50" width="50" />
                                                </div>
                                                <div class="profile-user-info">
                                                    <h4 class="mb-1 text-dark"><b>{{ind.company_name }}</b></h4>
                                                    <h4 class="mb-1">{{ind.comment }}</h4>
                                                    <div
                                                        class="d-flex align-items-center justify-content-between cursor">
                                                        <div
                                                            class="d-flex justify-content-between justify-content-sm-start">
                                                            <small class="text-muted mr-2">{{ ind.created_at }}</small>
                                                            <a href="javascript:void(0)"
                                                                (click)="commentlike(ind.id,ind.is_liked,ndx)"
                                                                class="d-flex align-items-center text-muted text-nowrap mr-2">
                                                                <i *ngIf="ind.is_liked == '1'"
                                                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50 red"
                                                                    aria-hidden="true"></i>
                                                                <i *ngIf="ind.is_liked == '' || ind.is_liked == '0'"
                                                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50"
                                                                    aria-hidden="true"></i>
                                                                <span>{{ ind.likes_comment_count }} Like </span>
                                                            </a>
                                                            <a href="javascript:void(0)"
                                                                (click)="commentunlike(ind.id,ind.is_liked,ndx)"
                                                                class="d-flex align-items-center text-muted text-nowrap mr-2">
                                                                <i *ngIf="ind.is_liked == '0'"
                                                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50 red"></i>
                                                                <i *ngIf="ind.is_liked == '' || ind.is_liked == '1'"
                                                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i>
                                                                <span>{{ ind.unlikes_comment_count }} Unlike </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Likes</a>
                            <ng-template ngbNavContent>
                                <div class="card" *ngFor="let ind of likeDetails; let ndx = index">
                                    <div class="card-body pb-0">
                                        <div class="d-flex justify-content-between align-items-center mb-1">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div class="avatar mr-1">
                                                    <img [src]="ind.avatar" alt="avatar img" height="50" width="50" />
                                                </div>
                                                <div class="profile-user-info">
                                                    <h4 class="mb-1 text-dark"><b>{{ind.user_company_name }}</b></h4>
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div
                                                            class="d-flex justify-content-between justify-content-sm-start">
                                                            <i *ngIf="ind.is_liked == '1'"
                                                                class="fa fa-thumbs-up profile-likes font-medium-3 mr-50 red"
                                                                aria-hidden="true"></i>
                                                            <i *ngIf="ind.is_liked == '0'"
                                                                class="fa fa-thumbs-down profile-likes font-medium-3 mr-50 red"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </ng-template>
            <!-- React Modal -->
            <!-- profile info section -->
            <section id="profile-info">
                <div class="row">
                    <!-- left profile info section -->
                    <div class="col-lg-3 col-12 order-2 order-lg-1"></div>
                    <div class="col-lg-6 col-12 order-1 order-lg-2">
                        <div class="text-center mb-1">
                            <button class="btn btn-success" (click)="details(modalSM)">Add Post</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- left profile info section -->
                    <div class="col-lg-3 col-12 order-2 order-lg-1">

                    </div>
                    <!--/ left profile info section -->
                    <!-- center profile info section -->
                    <div class="col-lg-6 col-12 order-1 order-lg-2" *ngIf="partypostList?.length != 0">
                        <!-- <div class="card" *ngFor="let postRef of data.profileData.post"> -->
                        <div class="card" *ngFor="let posts of partypostList; let mainndx = index">
                            <div class="card" *ngFor="let ind of posts; let ndx = index">
                                <div class="card-body pb-0">
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="avatar mr-1">
                                                <img [src]="ind.avatar" alt="avatar img" height="50" width="50" />
                                            </div>
                                            <div class="profile-user-info">
                                                <h4 class="mb-0 text-dark cursor"
                                                    routerLink="/profile/{{ ind.user_id }}">{{
                                                    ind.user_company_name |
                                                    titlecase}}</h4>
                                                <small class="text-muted">{{ ind.created_at }}</small>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="currentUser.data.id == '862'" class="profile-user-info" routerLink="/login">
                                                <div class="rating">
                                                    <ngb-rating [(rate)]="ind.rating" class="outline-none" [max]="5">
                                                    </ngb-rating>
                                                </div>
                                                <small class="text-muted cursor">{{ ind.rated_count }} Ratings</small>
                                            </div>
                                            <div *ngIf="currentUser.data.id != '862'" class="profile-user-info" routerLink="/profile/{{ ind.user_id }}">
                                                <div class="rating">
                                                    <ngb-rating [(rate)]="ind.rating" class="outline-none" [max]="5">
                                                    </ngb-rating>
                                                </div>
                                                <small class="text-muted cursor">{{ ind.rated_count }} Ratings</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bg-light-primary">
                                        <p class="card-text"><b>Name : </b>{{ ind.customer_name }}</p>
                                        <p class="card-text"><b>Company : </b>{{ ind.company_name }}</p>
                                        <p class="card-text"><b>Amount : </b>{{ ind.amount }}
                                            <b>DueDate: </b>{{ind.due_date}}
                                        </p>
                                    </div>
                                    <p class="card-text"><b>Mobile : </b>{{ ind.mobile }}</p>
                                    <p class="card-text"><b>City : </b>{{ ind.city }}</p>
                                    <p class="card-text"><b>Description : </b>{{ ind.description }}</p>
                                    <p class="card-text"><b>State : </b>{{ ind.state }}</p>
                                    <p class="card-text"><b>District : </b>{{ ind.district }}</p>
                                    <p class="card-text"><b>Address : </b>{{ ind.address }}</p>
                                    <hr>
                                    <div class="d-flex align-items-center justify-content-between cursor"
                                        (click)="Reactdetails(reactmodal,ind.id)">
                                        <div class="d-flex justify-content-between justify-content-sm-start">
                                            <a href="javascript:void(0)"
                                                class="d-flex align-items-center text-muted text-nowrap mr-2">
                                                <i class="fa fa-thumbs-up profile-likes font-medium-3 mr-50"
                                                    aria-hidden="true"></i>
                                                <span>{{ ind.likes_post_count }} Like </span>
                                            </a>
                                            <a href="javascript:void(0)"
                                                class="d-flex align-items-center text-muted text-nowrap mr-2">
                                                <i class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i>
                                                <span>{{ ind.unlikes_post_count }} Unlike </span>
                                            </a>
                                            <a href="javascript:void(0)"
                                                class="d-flex align-items-center text-muted text-nowrap">
                                                <i data-feather="message-square"
                                                    class="text-body font-medium-3 mr-50"></i>
                                                <!-- <i class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i> -->
                                                <span>{{ ind.comments_count }} Comment </span>
                                            </a>
                                        </div>
                                    </div>
                                    <hr>
                                    <!-- like share -->
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex justify-content-between justify-content-sm-start">
                                            <a href="javascript:void(0)"
                                                (click)="likePost(ind.id,ind.is_liked,mainndx,ndx)"
                                                class="d-flex align-items-center text-muted text-nowrap mr-2">
                                                <i *ngIf="ind.is_liked == '1'"
                                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50 red"
                                                    aria-hidden="true"></i>
                                                <i *ngIf="ind.is_liked == '' || ind.is_liked == '0'"
                                                    class="fa fa-thumbs-up profile-likes font-medium-3 mr-50"
                                                    aria-hidden="true"></i>
                                                <span>{{ ind.likes_count }} Like </span>
                                            </a>
                                            <a href="javascript:void(0)"
                                                (click)="unlikelikePost(ind.id,ind.is_liked,mainndx,ndx)"
                                                class="d-flex align-items-center text-muted text-nowrap">
                                                <i *ngIf="ind.is_liked == '0'"
                                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50 red"></i>
                                                <i *ngIf="ind.is_liked == '' || ind.is_liked == '1'"
                                                    class="fa fa-thumbs-down profile-likes font-medium-3 mr-50"></i>
                                                <span>{{ ind.unlikes_count }} Unlike </span>
                                            </a>
                                        </div>
                                    </div>
                                    <hr>
                                    <!-- comment box -->
                                    <fieldset class="form-label-group mb-50">
                                        <textarea class="form-control" id="label-textarea" rows="3"
                                            placeholder="Add Comment" name="comment"
                                            [(ngModel)]="commnetItemValues[ndx]"></textarea>
                                        <label for="label-textarea">Add Comment</label>
                                    </fieldset>
                                    <!--/ comment box -->
                                    <button type="button" (click)="addComment(ind.id,commnetItemValues[ndx])"
                                        class="btn btn-sm btn-primary" rippleEffect>Post
                                        Comment</button>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- reload button -->
                <div class="row" *ngIf="page <= last_page">
                    <div class="col-12 text-center" *ngIf="partypostList?.length != 0">
                        <button type="button" class="btn btn-sm btn-primary block-element border-0 mb-1" rippleEffect
                            (click)="loadMore()">
                            <span role="status" aria-hidden="true" [class.spinner-border]="loadMoreRef"
                                class="spinner-border-sm"></span>
                            Load More
                        </button>
                    </div>
                </div>
                <!--/ reload button -->
            </section>
            <!--/ profile info section -->
        </div>
    </div>
</div>