import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  constructor(private _http: HttpClient) { }

  getIndustry(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}idustry_list`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  edit(user_id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-profile`, {user_id});
  }

  update(person_name:any,company_name:any,address:any,state_id:any,district_id:any,city:any,email:any,gst_no:any,pan_no:any,business_category_id:any,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-profile`,{person_name,company_name,address,state_id,district_id,city,email,gst_no,pan_no,business_category_id,user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
