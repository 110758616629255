import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-login-v2',
  templateUrl: './auth-login-v2.component.html',
  styleUrls: ['./auth-login-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  public loadingOtp = false;
  public loadingpassword = false;
  public otpmsg: any = '';
  public is_register: any = '';
  public errorOtp: any = '';
  public errorPassword: any = '';
  public registerotpForm: FormGroup;
  public otp_screen = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private el: ElementRef,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    private _toastrService: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  get fotp() {
    return this.registerotpForm.controls;
  }
  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loading = true;
      const { mobile, password } = this.loginForm.controls;
      this.authenticationService.login(mobile.value, password.value).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            if (data.data.is_verify == '1') {
              this._router.navigate(['/home']);
            }
            else if (data.data.is_verify == '0') {
              this.otp_screen = true;
            }
          }
          else {
            this.error = data.message;
          }
        });
    }

    // // Login
    // this.loading = true;

    // // redirect to home page
    // setTimeout(() => {
    //   this._router.navigate(['/']);
    // }, 100);
  }

  onSubmitotp() {
    this.submitted = true;
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    // stop here if form is invalid
    if (this.registerotpForm.invalid) {

    }
    else {
      this.is_register = '1';
      this.otpmsg = '';
      this.loadingOtp = true;
      this.otpmsg = this.fotp.otp_one.value + '' + this.fotp.otp_two.value + '' + this.fotp.otp_three.value + '' + this.fotp.otp_four.value + '' + this.fotp.otp_five.value + '' + this.fotp.otp_six.value;
      this.authenticationService.verifyotp(this.f.mobile.value, this.otpmsg, this.is_register).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            // this.otp_screen = false;
            // this._router.navigate(['/home']);
            const { mobile, password } = this.loginForm.controls;
            this.authenticationService.login(mobile.value, password.value).subscribe(
              data => {
                this.loading = false;
                // if (data.status) {
                if (data.data.is_verify == '1') {
                  this._router.navigate(['/userprofile',data.data.id]);
                }
                else if (data.data.is_verify == '0') {
                  this.otp_screen = true;
                  // this._router.navigate(['/otpverify']);
                }
                else {
                  this.error = data.message;
                }
              });
          }
          else {
            this.error = data.message;
            //  this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {

        }
      );
    }
  }
  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + next + '"]');
      invalidControl.focus();
    }
  }
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      mobile: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.registerotpForm = this._formBuilder.group({
      otp_one: ['', [Validators.required]],
      otp_two: ['', [Validators.required]],
      otp_three: ['', [Validators.required]],
      otp_four: ['', [Validators.required]],
      otp_five: ['', [Validators.required]],
      otp_six: ['', [Validators.required]]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
