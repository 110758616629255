<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
        <div id="user-profile">
            <div class="row">
                <!-- left profile info section -->
                <div class="col-lg-1 col-12 order-2 order-lg-1">

                </div>
                <!--/ left profile info section -->
                <!-- center profile info section -->
                <div class="col-lg-10 col-12 order-1 order-lg-2">
                    <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                        <div class="card">
                            <div class="card-body pb-0">
                                <!-- <div class="d-flex justify-content-between align-items-center mb-1"> -->
                                <div class="row m-0">
                                    <div class="form-group col-lg-4">
                                        <label for="register-company_name" class="form-label">Company Name<span
                                                class="text-danger">*</span> </label>
                                        <input type="text" tabindex="1" formControlName="company_name" class="form-control"
                                            placeholder="Enter Company Name" aria-describedby="register-company_name"
                                            [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                                        <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.company_name.errors.required">Company Name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-person_name" class="form-label">Name<span
                                                class="text-danger">*</span> </label>
                                        <input tabindex="2" type="text" formControlName="person_name" class="form-control"
                                            placeholder="Enter Name" aria-describedby="register-person_name"
                                            [ngClass]="{ 'is-invalid': submitted && f.person_name.errors }" />
                                        <div *ngIf="submitted && f.person_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.person_name.errors.required">Name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-mobile" class="form-label">Mobile<span
                                                class="text-danger">*</span> </label>
                                        <input tabindex="3" type="text" formControlName="mobile" class="form-control"
                                            placeholder="Enter Mobile" aria-describedby="register-mobile"
                                            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-state_id" class="form-label">State<span
                                                class="text-danger">*</span></label>
                                        <select formControlName="state_id" class="form-control"
                                            (change)="getDist($event)" placeholder="Select State"
                                            aria-describedby="register-state_id" tabindex="4"
                                            [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                            <option value="" selected disabled>Select State</option>
                                            <option *ngFor="let state of stateDropDown" [value]="state.id">
                                                {{state.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-district_id" class="form-label">District<span
                                                class="text-danger">*</span></label>
                                        <select formControlName="district_id" class="form-control"
                                            placeholder="Select District" aria-describedby="register-district_id"
                                            tabindex="5"
                                            [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }">
                                            <option value="0" selected disabled>Select District</option>
                                            <option *ngFor="let dis of distictDropDown" [value]="dis.id">
                                                {{dis.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.district_id.errors.required">District is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-city" class="form-label">City<span
                                                class="text-danger"></span>
                                        </label>
                                        <input tabindex="6" type="text" formControlName="city" class="form-control"
                                            placeholder="Enter City" aria-describedby="register-city"
                                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                            <div *ngIf="f.city.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-gst_no" class="form-label">GST No<span
                                                class="text-danger"></span> </label>
                                        <input tabindex="7" type="text" formControlName="gst_no" class="form-control"
                                            placeholder="Enter GST No" aria-describedby="register-gst_no"
                                            [ngClass]="{ 'is-invalid': submitted && f.gst_no.errors }" />
                                        <div *ngIf="submitted && f.gst_no.errors" class="invalid-feedback">
                                            <div *ngIf="f.gst_no.errors.required">GST No is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-pan_no" class="form-label">PAN No<span
                                                class="text-danger"></span> </label>
                                        <input tabindex="8" type="text" formControlName="pan_no" class="form-control"
                                            placeholder="Enter PAN No" aria-describedby="register-pan_no"
                                            [ngClass]="{ 'is-invalid': submitted && f.pan_no.errors }" />
                                        <div *ngIf="submitted && f.pan_no.errors" class="invalid-feedback">
                                            <div *ngIf="f.pan_no.errors.required">PAN No is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address" class="form-label">Address<span
                                                class="text-danger"></span> </label>
                                        <input tabindex="9" type="text" formControlName="address" class="form-control"
                                            placeholder="Enter Address" aria-describedby="register-address"
                                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                            <div *ngIf="f.address.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-email" class="form-label">Email<span
                                                class="text-danger"></span> </label>
                                        <input tabindex="10" type="text" formControlName="email" class="form-control"
                                            placeholder="Enter Email" aria-describedby="register-email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-business_industry_id" class="form-label"> Business Industry<span class="text-danger">*</span></label>
                                        <select formControlName="business_industry_id" class="form-control"
                                            placeholder="Select State" aria-describedby="register-business_industry_id"
                                            tabindex="11"
                                            [ngClass]="{ 'is-invalid': submitted && f.business_industry_id.errors }">
                                            <!-- <option value="" selected disabled>Select Business Industry</option> -->
                                            <option *ngFor="let inds of industryDropDown" [value]="inds.id">
                                                {{inds.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.business_industry_id.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="f.business_industry_id.errors.required">Business Industry is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <div class="form-group col-lg-4">
                                        <div class="col-lg-12 p-0">
                                            <button tabindex="12" type="submit" class="btn btn-primary" rippleEffect
                                                [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>