import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RequirementService } from 'app/_services/requirement.service';
@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss']
})
export class RequirementComponent implements OnInit {
  public postList: any = [];
  public post_profile: any = 0;
  public post_id: any = 0;
  public loadMoreRef = false;
  public page: number = 1;
  public last_page: number = 1;

  public newFormAdd: FormGroup;
  public newFormReport: FormGroup;
  public submitted = false;
  public error = "";
  public is_make_request = 0;
  public user_id: any = '';
  public search_text: any = '';
  public loadingFrm: boolean = false;

  public isLoading: boolean = false;
  public allName: any;
  public keyword = 'name';
  public productName = '';
  public historyHeading: string = 'Recently selected';

  public image: any = '';

  public imageR: boolean = true;
  public textR: boolean = false;

  public currentUser: any;
  public usr_id: any;
  constructor(
    private requirementService: RequirementService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.postsList();

    this.newFormAdd = this._formBuilder.group({
      name: [""],
      is_img: ["1", Validators.required],
      image: [""],
      description: [""],
      content: [""],
    });

    this.newFormReport = this._formBuilder.group({
      remarks: ["", Validators.required]
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  get fr() {
    return this.newFormReport.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const formdata = new FormData();
      formdata.append('image', this.image);
      // formdata.append('business_category_id', this.f.business_category_id.value);
      if (this.productName != '') {
        formdata.append('name', this.productName);
      } else {
        formdata.append('name', this.f.name.value);
      }
      formdata.append('is_img', this.f.is_img.value);
      formdata.append('description', this.f.description.value);
      formdata.append('content', this.f.content.value);
      this.requirementService.add(formdata).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormAdd.reset();
            this.modalService.dismissAll();
            this.page = 1;
            this.postList = [];
            this.reloadList();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormAdd.reset();
            this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  typeChange(type) {
    this.imageR = false;
    this.textR = false;
    if (type == 0) {
      this.textR = true;
    } else {
      this.imageR = true;
    }
  }
  reloadList() {
    this.postsList();
  }

  postsList() {
    this.requirementService.getpost(this.page, this.user_id, this.search_text).subscribe(
      (response) => {
        if (response.status) {
          this.postList.push(response.data.data);
          this.last_page = response.data.last_page;
          this.page += 1;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  loadMore() {
    this.loadMoreRef = !this.loadMoreRef;
    setTimeout(() => {
      this.loadMoreRef = !this.loadMoreRef;
    }, 2000);
    this.postsList();
  }

  details(modalSM) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.modalService.open(modalSM, {
        centered: true,
        size: 'xl'
      });
    }
  }

  report(post_id, modalreport) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.post_id = post_id;
      this.modalService.open(modalreport, {
        centered: true,
        size: 'lg'
      });
    }
  }

  onSubmitReport() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormReport.invalid) {
      this.newFormReport.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      this.post_profile = "1";
      const { remarks } = this.newFormReport.controls;
      this.requirementService.addReport(remarks.value, this.currentUser.data.id, this.post_id, this.post_profile).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormReport.reset();
            this.modalService.dismissAll();
            this.page = 1;
            this.postList = [];
            this.reloadList();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormReport.reset();
            this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.requirementService.productSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allName = data.data;
        }
        else {
          this.isLoading = false;
          this.allName = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allName = [];
      }
    );
  }

  selectEvent(item: any) {
    this.productName = item.name;
  }
  onFocused(e: any) {
  }

  userPost(user_id) {
    this.user_id = user_id;
    this.page = 1;
    this.postList = [];
    this.reloadList();
  }
  searchText() {
    this.search_text;
    this.page = 1;
    this.postList = [];
    this.reloadList();
  }
  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.image = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          // this.imageAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  likePost(market_place_id, is_liked, mainndx, ndx) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "1";
      }

      this.requirementService.likepost(market_place_id, like).subscribe(
        data => {
          if (data.status) {
            this.postList[mainndx][ndx].is_liked = like;
            this.postList[mainndx][ndx].likes_count = data.data.likes_count;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  unlikelikePost(market_place_id, is_liked, mainndx, ndx) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "0";
      }

      this.requirementService.likepost(market_place_id, like).subscribe(
        data => {
          if (data.status) {
            this.postList[mainndx][ndx].is_liked = like;
            this.postList[mainndx][ndx].unlikes_count = data.data.unlikes_count;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }
}
