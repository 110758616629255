import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PartypostService {

  constructor(private _http: HttpClient) { }

  getState(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-states`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDistrict(state_id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-districts`,{ state_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(customer_name: any, company_name: any, gst_no: any, pan_no: any, mobile: any, amount: any, due_date: any, state_id: any, district_id: any, city: any, address: any, description: any,business_industry_id): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}add-post`, { customer_name, company_name, gst_no,pan_no,mobile,amount,due_date,state_id,district_id,city,address,description,business_industry_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getpost(page): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-post?page=` + page, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getcomment(post_id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-comment`,{ post_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getlikes(post_id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-react`,{ post_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  likepost(post_id,is_liked): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}add-like-unlike`, {post_id,is_liked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  likecomment(comment_id,is_liked): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}add-like-unlike`, {comment_id,is_liked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  unlikecomment(comment_id,is_liked): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}add-like-unlike`, {comment_id,is_liked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  commentadd(post_id,comment): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}add-comment`, {post_id,comment })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
