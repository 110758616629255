import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  sentOtp(mobile): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}forgot-password`, { mobile })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  verifyotp(mobile: any, otp: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}verify-otp`, { mobile, otp })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  changePassword(user_id: any, new_password: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}reset-password`, { user_id, new_password })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  resendotp(mobile): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}resend-otp`, { mobile })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
