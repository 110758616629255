<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
      <h2 class="brand-text text-primary ml-1">Bacimo</h2>
    </a>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-7 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login-v2-dark.svg'
              : 'assets/images/pages/login-v2.svg'
          " alt="Login V2" />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Login-->
    <div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5" *ngIf="!otp_screen">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Welcome to Bacimo! 👋</h2>
        <p class="card-text mb-2">Please sign-in to your account and start the adventure</p>

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>

        <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="form-label" for="login-mobile">Mobile</label>
            <input type="text" formControlName="mobile" class="form-control" minlength="10" maxlength="10"
              [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" placeholder="Mobile"
              aria-describedby="login-mobile" autofocus="" tabindex="1" />
            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
              <div *ngIf="f.mobile.errors.required">Mobile is required</div>
              <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
              <a routerLink="/forgot-password"><small>Forgot Password?</small></a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" placeholder="············"
                aria-describedby="login-password" tabindex="2" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
              <label class="custom-control-label" for="remember-me"> Remember Me</label>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
          </button>
        </form>
        <br>
        <button [disabled]="loading" [routerLink]="['/']" class="btn btn-primary btn-block"rippleEffect>
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Back to Home
        </button>
        <!-- <p class="text-center mt-2">
          <span>New on our platform?</span
          ><a routerLink="/pages/authentication/register-v2"><span>&nbsp;Create an account</span></a>
        </p>
        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <a class="btn btn-facebook" href="javascript:void(0)"><i data-feather="facebook"></i></a
          ><a class="btn btn-twitter white" href="javascript:void(0)"><i data-feather="twitter"></i></a
          ><a class="btn btn-google" href="javascript:void(0)"><i data-feather="mail"></i></a
          ><a class="btn btn-github" href="javascript:void(0)"><i data-feather="github"></i></a>
        </div> -->
      </div>
    </div>
    <!-- /Login-->
    <!-- /OTP Verify-->
    <div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5" *ngIf="otp_screen">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
          <h2 class="card-title font-weight-bold mb-1 text-center">OTP Verification</h2>
          <p class="card-text mb-2 text-center">Please enter the verification code sent to your number</p>
          <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
            <div class="alert-body">
              <p>{{ error }}</p>
            </div>
          </ngb-alert>
          <form class="auth-register-form mt-2" [formGroup]="registerotpForm" (ngSubmit)="onSubmitotp()">
              <div class="form-group text-center pb-1 pt-3">
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_two')" formControlName="otp_one" class="form-control text-center" aria-describedby="register-otp_one" tabindex="1" />
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_three')" formControlName="otp_two" class="form-control text-center" aria-describedby="register-otp_two" tabindex="2" />
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_four')" formControlName="otp_three" class="form-control text-center" aria-describedby="register-otp_three" tabindex="3" />
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_five')" formControlName="otp_four" class="form-control text-center" aria-describedby="register-otp_four" tabindex="4" />
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_six')" formControlName="otp_five" class="form-control text-center" aria-describedby="register-otp_five" tabindex="5" />
                  <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,6)" formControlName="otp_six" class="form-control text-center" aria-describedby="register-otp_six" tabindex="6" />
              </div>
              <!-- <div class="form-group text-center">
                  <p class="text-center">
                      Don't Receive the OTP?
                      <a class="text-primary" style="font-weight: 900;" (click)="resendotp()">Resend OTP</a>
                  </p>
              </div> -->
              <!-- <center> -->
                  <!-- <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
                    </button> -->
                  <button [disabled]="loadingOtp" class="btn btn-primary btn-block" tabindex="7" rippleEffect>
                  <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>VERIFY
              </button>
              <!-- </center> -->
              <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorOtp">
                  <div class="alert-body">
                      <p>{{ errorOtp }}</p>
                  </div>
              </ngb-alert>
          </form>
      </div>
    </div>
    <!-- /OTP Verify-->
  </div>
</div>