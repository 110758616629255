<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
      <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
        <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
        <h2 class="brand-text text-primary ml-1">Bacimo</h2>
      </a>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <div class="d-none d-lg-flex col-lg-7 align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <img class="img-fluid" [src]="'assets/images/pages/forgot-password-v2.svg'
            " alt="Login V2" />
        </div>
      </div>
      <!-- /Left Text-->
      <!-- Login-->
      <div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5">
        <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
            <h2 class="card-title font-weight-bold mb-1 text-center">OTP Verification</h2>
            <p class="card-text mb-2 text-center">Please enter the verification code sent to your number</p>
            <form class="auth-register-form mt-2" [formGroup]="registerotpForm" (ngSubmit)="onSubmitotp()">
                <div class="form-group text-center pb-1 pt-3">
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_two')" formControlName="otp_one" class="form-control text-center" aria-describedby="register-otp_one" tabindex="1" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_three')" formControlName="otp_two" class="form-control text-center" aria-describedby="register-otp_two" tabindex="2" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_four')" formControlName="otp_three" class="form-control text-center" aria-describedby="register-otp_three" tabindex="3" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_five')" formControlName="otp_four" class="form-control text-center" aria-describedby="register-otp_four" tabindex="4" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_six')" formControlName="otp_five" class="form-control text-center" aria-describedby="register-otp_five" tabindex="5" />
                    <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,6)" formControlName="otp_six" class="form-control text-center" aria-describedby="register-otp_six" tabindex="6" />
                </div>
                <!-- <div class="form-group text-center">
                    <p class="text-center">
                        Don't Receive the OTP?
                        <a class="text-primary" style="font-weight: 900;" (click)="resendotp()">Resend OTP</a>
                    </p>
                </div> -->
                <!-- <center> -->
                    <!-- <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
                      </button> -->
                    <button [disabled]="loadingOtp" class="btn btn-primary btn-block" tabindex="7" rippleEffect>
                    <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>VERIFY
                </button>
                <!-- </center> -->
                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorOtp">
                    <div class="alert-body">
                        <p>{{ errorOtp }}</p>
                    </div>
                </ngb-alert>
            </form>
        </div>
      </div>
      
      <!-- /Login-->
    </div>
  </div>