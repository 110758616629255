import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RequirementService {

  constructor(private _http: HttpClient) { }

  getpost(page,user_id,search_text): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-market-place?page=` + page, { user_id,search_text })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  productSearch(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}autoComplete`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}add-market-place`,  formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addReport(remarks:any,user_id:any,post_id:any,post_profile:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}post-report`,{remarks,user_id,post_id,post_profile})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  likepost(market_place_id,is_liked): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}market-place-add-like-unlike`, {market_place_id,is_liked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
