import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { AuthenticationService, UserService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public industryList: any = [];
  public loading = false;
  public error = '';
  public currentUser: any;
  
  constructor(
    private metaService: Meta,
    private userService: UserService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private http: HttpClient,
  ) { }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // this.metaService.updateTag({ name: 'description', content: 'Your dynamic meta description' });
    // this.metaService.updateTag({ name: 'keywords', content: 'Your dynamic meta description' });
    // this.metaService.updateTag({ name: 'author', content: 'Your dynamic meta description' });
    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (this.currentUser == null) {
      // direct api call
      this._authenticationService.login('9773029495', '123456','0').subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this._router.navigate(['/home']);
            this.industrysList();
          }
          else {
            this.error = data.message;
          }
        });
      // direct api call
    } else {
      this.industrysList();

    }

    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }
  }

  industrysList() {
    this.userService.getindustry().subscribe(
      (response) => {
        if (response.status) {
          this.industryList = response.data;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
}
