import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { PostService } from 'app/_services/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title, Meta } from '@angular/platform-browser';
import Swal from "sweetalert2";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public postList: any = [];
  public requirementpostList: any = [];
  public business_category_id: any = 0;
  public post_profile: any = 0;
  public post_id: any = 0;
  public loadMoreRef = false;
  public reqloadMoreRef = false;
  public page: number = 1;
  public reqpage: number = 1;
  public last_page: number = 1;
  public reqlast_page: number = 1;

  public newFormAdd: FormGroup;
  public newFormReport: FormGroup;
  public submitted = false;
  public error = "";
  public is_make_request = null;
  public user_id: any = '';
  public search_text: any = '';
  public loadingFrm: boolean = false;

  public isLoading: boolean = false;
  public allName: any;
  public keyword = 'name';
  public productName = '';
  public historyHeading: string = 'Recently selected';

  public image: any = '';

  public imageR: boolean = true;
  public textR: boolean = false;

  public currentUser: any;
  public usr_id: any;
  public market_place_id: number = 0;

  constructor(
    private metaService: Meta,
    private postService: PostService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    // this.metaService.updateTag({ name: 'description', content: 'post page' });
    // this.metaService.updateTag({ name: 'keywords', content: 'post page' });
    // this.metaService.updateTag({ name: 'author', content: 'post page' });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.business_category_id = this.route.snapshot.params['id'];
    this.postsList(this.business_category_id);
    this.requirepostsList(this.business_category_id);

    this.newFormAdd = this._formBuilder.group({
      name: [""],
      is_img: ["1", Validators.required],
      business_category_id: [this.business_category_id],
      image: [""],
      description: [""],
      content: [""],
      market_place_id: ["0"],
    });

    this.newFormReport = this._formBuilder.group({
      remarks: ["", Validators.required]
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  get fr() {
    return this.newFormReport.controls;
  }

  onSubmit(is_type) {
    this.submitted = true;
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const formdata = new FormData();
      formdata.append('image', this.image);
      if (is_type == '1') {
        this.is_make_request = '1';
        formdata.append('is_make_request', this.is_make_request);
      }
      formdata.append('business_category_id', this.f.business_category_id.value);
      if (this.productName != '') {
        formdata.append('name', this.productName);
      } else {
        formdata.append('name', this.f.name.value);
      }
      formdata.append('is_img', this.f.is_img.value);
      formdata.append('description', this.f.description.value);
      formdata.append('content', this.f.content.value);
      formdata.append('market_place_id', this.f.market_place_id.value);
      this.postService.add(formdata).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormAdd.reset();
            this.modalService.dismissAll();
            if (is_type == '0') {
              this.page = 1;
              this.is_make_request = null;
            } else if (is_type == '1') {
              this.reqpage = 1;
              this.is_make_request = '1';
            }
            this.postList = [];
            this.requirementpostList = [];
            this.reloadList();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormAdd.reset();
            this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  typeChange(type) {
    this.imageR = false;
    this.textR = false;
    if (type == 0) {
      this.textR = true;
    } else {
      this.imageR = true;
    }
  }

  reloadList() {
    this.business_category_id = this.route.snapshot.params['id'];
    this.postsList(this.business_category_id);
    this.requirepostsList(this.business_category_id);
  }

  postsList(business_category_id: any) {
    this.is_make_request = null;
    this.postService.getpost(business_category_id, this.page, this.user_id, this.search_text, this.is_make_request).subscribe(
      (response) => {
        if (response.status) {
          this.postList.push(response.data.data);
          this.last_page = response.data.last_page;
          this.page += 1;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  requirepostsList(business_category_id: any) {
    this.is_make_request = '1';
    this.postService.getpost(business_category_id, this.reqpage, this.user_id, this.search_text, this.is_make_request).subscribe(
      (response) => {
        if (response.status) {
          this.requirementpostList.push(response.data.data);
          this.reqlast_page = response.data.last_page;
          this.reqpage += 1;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  loadMore() {
    this.loadMoreRef = !this.loadMoreRef;
    setTimeout(() => {
      this.loadMoreRef = !this.loadMoreRef;
    }, 2000);
    this.postsList(this.business_category_id);
  }
  loadMorereq() {
    this.reqloadMoreRef = !this.reqloadMoreRef;
    setTimeout(() => {
      this.reqloadMoreRef = !this.reqloadMoreRef;
    }, 2000);
    this.requirepostsList(this.business_category_id);
  }

  details(modalSM) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.modalService.open(modalSM, {
        centered: true,
        size: 'xl'
      });
    }
  }

  report(post_id, modalreport) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      this.post_id = post_id;
      this.modalService.open(modalreport, {
        centered: true,
        size: 'lg'
      });
    }
  }

  onSubmitReport() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormReport.invalid) {
      this.newFormReport.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      this.post_profile = "1";
      const { remarks } = this.newFormReport.controls;
      this.postService.addReport(remarks.value, this.currentUser.data.id, this.post_id, this.post_profile).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.newFormReport.reset();
            this.modalService.dismissAll();
            this.page = 1;
            this.postList = [];
            this.reloadList();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
          } else {
            this.newFormReport.reset();
            this.modalService.dismissAll();
            this._toastrService.error(data.message, 'Error!');
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  onChangeSearch(business_category_id, value: string) {
    this.isLoading = true
    this.postService.productSearch(business_category_id, value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allName = data.data;
        }
        else {
          this.isLoading = false;
          this.allName = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allName = [];
      }
    );
  }

  selectEvent(item: any) {
    this.productName = item.name;
  }
  onFocused(e: any) {
  }

  userPost(user_id, type) {
    this.user_id = user_id;
    if (type == '0') {
      this.page = 1;
      this.postList = [];
      this.reloadList();
    } else if (type == '1') {
      this.reqpage = 1;
      this.requirementpostList = [];
      this.reloadList();
    }

  }
  searchText(type) {
    if (type == '0') {
      this.search_text;
      this.page = 1;
      this.postList = [];
      this.business_category_id = this.route.snapshot.params['id'];
      this.postsList(this.business_category_id);
    } else if (type == '1') {
      this.search_text;
      this.reqpage = 1;
      this.requirementpostList = [];
      this.business_category_id = this.route.snapshot.params['id'];
      this.requirepostsList(this.business_category_id);
    }
  }
  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.image = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          // this.imageAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  likePost(market_place_id, is_liked, mainndx, ndx, type) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "1";
      }

      this.postService.likepost(market_place_id, like).subscribe(
        data => {
          if (data.status) {
            if (type == '0') {
              this.postList[mainndx][ndx].is_liked = like;
              this.postList[mainndx][ndx].likes_count = data.data.likes_count;
            } else if (type == '2') {
              this.requirementpostList[mainndx][ndx].is_liked = like;
              this.requirementpostList[mainndx][ndx].likes_count = data.data.likes_count;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  unlikelikePost(market_place_id, is_liked, mainndx, ndx, type) {
    if (this.currentUser.data.id == '862') {
      this._router.navigate(['/login']);
    } else {
      let like = '';
      if (is_liked == '') {
        like = "0";
      }

      this.postService.likepost(market_place_id, like).subscribe(
        data => {
          if (data.status) {
            if (type == '1') {
              this.postList[mainndx][ndx].is_liked = like;
              this.postList[mainndx][ndx].unlikes_count = data.data.unlikes_count;
            } else if (type == '3') {
              this.requirementpostList[mainndx][ndx].is_liked = like;
              this.requirementpostList[mainndx][ndx].unlikes_count = data.data.unlikes_count;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  delete(id: any, is_type) {
    console.log(id);

    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.postService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              if (is_type == '0') {
                me.page = 1;
              } else if (is_type == '1') {
                me.reqpage = 1;
              }
              me.postList = [];
              me.requirementpostList = [];
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Post Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  edit(modalSM, id: any, is_type, mainndx, ndx, edit_type) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.imageR = false;
    this.textR = false;
    if (is_type == 0) {
      this.textR = true;
    } else {
      this.imageR = true;
    }
    if (edit_type == 0) {
      this.f.business_category_id.setValue(this.route.snapshot.params['id']);
      this.f.market_place_id.setValue(this.postList[mainndx][ndx].id);
      this.f.is_img.setValue(this.postList[mainndx][ndx].is_img);
      this.f.content.setValue(this.postList[mainndx][ndx].content);
      this.f.name.setValue(this.postList[mainndx][ndx].name);
      this.f.description.setValue(this.postList[mainndx][ndx].description);
    }else{
      this.f.business_category_id.setValue(this.route.snapshot.params['id']);
      this.f.market_place_id.setValue(this.requirementpostList[mainndx][ndx].id);
      this.f.is_img.setValue(this.requirementpostList[mainndx][ndx].is_img);
      this.f.content.setValue(this.requirementpostList[mainndx][ndx].content);
      this.f.name.setValue(this.requirementpostList[mainndx][ndx].name);
      this.f.description.setValue(this.requirementpostList[mainndx][ndx].description);
    }
  }
}
