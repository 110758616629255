import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private _http: HttpClient) { }

  getpost(business_category_id, page,user_id,search_text,is_make_request): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-market-place?page=` + page, { business_category_id,user_id,search_text,is_make_request })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  productSearch(business_category_id,searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}autoComplete`, {business_category_id, searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  // add(business_category_id:any,name:any,is_img:any,description:any,image:any,is_make_request:any): Observable<any> {
  add(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}add-market-place`,  formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addReport(remarks:any,user_id:any,post_id:any,post_profile:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}post-report`,{remarks,user_id,post_id,post_profile})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  likepost(market_place_id,is_liked): Observable<any> {
    
    return this._http
      .post(`${environment.apiUrl}market-place-add-like-unlike`, {market_place_id,is_liked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(market_place_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-market-place`, { market_place_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
