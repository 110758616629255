import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { AuthGuard } from './auth/helpers';
import { HomeComponent } from './main/sample/home.component';
import { Role } from './auth/models';
import { PostComponent } from './component/post/post.component';
import { ProfileComponent } from './component/profile/profile.component';
import { PartypostComponent } from './component/partypost/partypost.component';
import { UserprofileComponent } from './component/userprofile/userprofile.component';
import { RequirementComponent } from './component/requirement/requirement.component';
import { OtpverifyComponent } from './main/pages/authentication/otpverify/otpverify.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    // redirectTo: '/login',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'otpverify',
    component: OtpverifyComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'home',
    component: HomeComponent,
    // canActivate: [AuthGuard],
    data: { animation: 'auth' }
  },
  {
    path: 'post/:id',
    component: PostComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: 'partypost',
    component: PartypostComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: 'userprofile/:id',
    component: UserprofileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: 'requirement',
    component: RequirementComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled', // Add options right here
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }