import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) { }

  getprofile(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-profile`, {user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addReport(remarks:any,user_id:any,post_id:any,post_profile:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}post-report`,{remarks,user_id,post_id,post_profile})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  addRating(rating:any,review:any,to_user_id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}add-rating-review`,{rating,review,to_user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getrating(to_user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-rating-review`, {to_user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  block(to_user_id,is_blocked): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}block-unblock-profile`, {to_user_id,is_blocked })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
